import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {
    findCompaniesByQueryString,
    findSectorsByQueryString,
    getCompanyById, isUserAdmin,
    userHasPermission
} from "../../utils/requestUtils";
import {UserPermission} from "../../utils/utils";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;

const UserManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const url = window.location.pathname;
    let admin = false;
    if (url.startsWith('/admin')) {
        admin = true
    }

    const newEntity = {
        id: undefined,
        name: '',
        email: '',
        permissions: [],
        password: '',
    };

    const [formData, setFormData] = useState(newEntity);
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [originalPermissions, setOriginalPermissions] = useState([]);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);
    const [companies, setCompanies] = useState([]);
    const [userCompany, setUserCompany] = useState(undefined);


    useEffect(() => {
        fetchPermissions();
        if(url.startsWith('/admin')) {
            isUserAdmin().then(userAdmin => {
                if (!userAdmin)
                    navigate('/unauthorizedaccess');
            });
        }
    }, []);

    useEffect(() => {
        if (formData.companyId !== undefined) {
            getCompanyById(formData.companyId).then(response => setUserCompany(response));
        } else {
            setUserCompany(undefined);
        }
    }, [formData.companyId]);

    useEffect(() => {

        const filteredPermissions = Object.entries(originalPermissions)
            .filter(([key, value]) =>
                userCompany.permissions.some(up => up === key)
            )
            .map(([key, value]) => key);

        const newAvailablePermissions = filteredPermissions.reduce((acc, key) => {
            acc[key] = originalPermissions[key];
            return acc;
        }, {});

        console.log(filteredPermissions);
        setAvailablePermissions(newAvailablePermissions);
    }, [userCompany]);

    useEffect(() => {
        if (hasCreatePermission === false || (hasEditPermission === false && id)) {
            navigate('/unauthorizedaccess');
        }
    }, [hasEditPermission, hasCreatePermission]);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handlePermissionChange = (e) => {
        const {value, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            permissions: checked
                ? [...prevData.permissions, value]
                : prevData.permissions.filter(permission => permission !== value)
        }));
    };

    async function fetchPermissions() {
        try {
            setLoading(true);
            const response = await makeRequest(apiUrl + '/api/userpermission/users_permission_info', 'GET', {
                'Useruuid': Cookies.get('userUUID'),
            });

            const permissionsMap = response.reduce((acc, permission) => {
                acc[permission.permission] = {description: permission.description, isChecked: false, isEnabled: true};
                return acc;
            }, {});
            setOriginalPermissions({...permissionsMap});
            setAvailablePermissions({...permissionsMap});
        } catch (error) {
            console.error('Error fetching permissions:', error);
        } finally {
            setLoading(false);
        }
    }


    async function makeQuery() {
        if (id) {
            try {
                setLoading(true);
                const url = apiUrl + '/api/users/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        if(admin) {
            setEditPermission(true);
            setCreatePermission(true);
        } else {
            userHasPermission(UserPermission.EDIT_USER).then(userHasPermission => setEditPermission(userHasPermission));
            userHasPermission(UserPermission.CREATE_USER).then(userHasPermission => setCreatePermission(userHasPermission));
        }
        findCompaniesByQueryString("").then(companies => setCompanies(companies));
        makeQuery();
    }, [id]);

    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                navigate(`/${admin ? 'admin' : 'app'}/userlist`);
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {
        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }
        return true;
    }

    async function saveEntity() {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/users';
        setLoading(true);
        try {
            await makeRequest(url, id ? 'PUT' : 'POST', {
                'Content-Type': 'application/json',
                'Useruuid': userUUID,
            }, JSON.stringify(formData));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    function handleCompanySelected(value) {

            const company = value !== undefined ? companies.find((p) => p.id === Number(value)) : undefined;
            setFormData((prevData) => ({
                ...prevData,
                companyId: company?.id
            }));

    }

    if (hasCreatePermission === undefined
        || hasEditPermission === undefined) {
        return <Loading/>
    }

    return (
        <div style={{width: '500px'}}>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Usuário</div>

            {admin &&
                <>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div>Empresa:</div>
                        <select value={formData?.companyId}
                                onChange={(e) => handleCompanySelected(e.target.value)}>
                            <option value="">Selecione a Empresa</option>
                            {companies.map((company, index) => (
                                <option key={index} value={company.id}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div style={{marginTop: '5px', alignItems: 'center'}}>
                        <div style={{height: '17px'}}>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="checkbox"
                                   checked={formData.adminUser}
                                   name="adminUser"
                                   onChange={handleChange}/>
                            <div>Administrador?</div>

                        </div>
                    </div>
                </>
            }

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>e-mail:</div>
                <input type="email" style={{width: '300px', textTransform: 'none'}} value={formData.email} name="email"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Senha:</div>
                <input type="password" style={{width: '300px', textTransform: 'none'}} value={formData.password}
                       name="password"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', overflowY: 'auto', maxHeight: '300px'}}>
                <div style={{width: '180px'}}>Permissões:</div>
                {admin && formData.companyId === undefined ?
                    <>Selecione uma empresa antes de definir as permissões.</>
                    :
                    (Object.entries(availablePermissions).map(([permission, values]) => (
                        <div key={permission} style={{display: "flex", alignItems: "center"}}>
                            <label style={{display: "flex", alignItems: "center"}}>
                                <input
                                    type="checkbox"
                                    value={permission}
                                    checked={formData.permissions.includes(permission)}
                                    onChange={handlePermissionChange}
                                />
                                {values.description}
                            </label>
                        </div>
                    )))}

            </div>

            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    );
};

export default UserManager;
