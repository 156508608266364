import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";

import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {findCompaniesByQueryString, isUserAdmin} from "../../utils/requestUtils";
import Config from "../../core/config";

const apiUrl = Config.apiUrl;
const CompanyList = () => {
    let [companies, setCompanies] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();

    async function makeQuery(query) {
        try {
            setLoading(true);
            const response = await findCompaniesByQueryString(query);
            setCompanies(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        isUserAdmin().then(userAdmin => {
            if (!userAdmin)
                navigate('/unauthorizedaccess');
        });
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        makeQuery('');
    }, []);

    async function deleteCompany(id) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/companies/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Consulta de Empresas</div>
                    <button className={"new-button"} onClick={() => navigate('/admin/company')}>Novo</button>
            </div>

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
            </div>

            <table style={{width: '580px'}}>

                <tr>
                    <th>
                        Nome
                    </th>
                    <th>
                        Cidade
                    </th>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>

                {companies.map((company, index) => (


                    <tr>

                        <td>
                            <div key={index}>{company.name}</div>
                        </td>
                        <td>
                            <div key={index}>{company.city}</div>
                        </td>
                        <td>
                                <button onClick={async () => {
                                    await navigate('/admin/company?id=' + company.id);
                                }}>Editar
                                </button>
                        </td>
                        <td>
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await deleteCompany(company.id);
                                    }
                                }}>Excluir
                                </button>
                        </td>

                    </tr>
                ))}
                </tbody>
                {companies.length === 0 && (<tfoot>{loading ? 'Carregando...' : `Nenhum item encontrado.`}</tfoot>)}
            </table>


        </>
    );
};

export default CompanyList;
