import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import Loading from "../../core/Loading";
import {userHasPermission} from "../../utils/requestUtils";
import {renderPaymentConditionOptions, renderSaleOptions, UserPermission} from "../../utils/utils";

const apiUrl = Config.apiUrl;

const PaymentTermManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        name: '',
    };

    const [formData, setFormData] = useState(newEntity);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);

    useEffect(() => {
        if(hasCreatePermission === false || (hasEditPermission === false && id)) {
            navigate('/unauthorizedaccess');
        }
    }, [hasEditPermission, hasCreatePermission]);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                setLoading(true);
                const url = apiUrl + '/api/payment_terms/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.EDIT_PAYMENT_TERM).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_PAYMENT_TERM).then(userHasPermission => setCreatePermission(userHasPermission));
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                navigate('/app/paymenttermlist');
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {

        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }

        return true;
    }

    async function saveEntity() {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/payment_terms';
        setLoading(true);
        try {
            await makeRequest(url, id ? 'PUT' : 'POST', {
                'Content-Type': 'application/json',
                'Useruuid': userUUID,
            }, JSON.stringify(formData));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }

    if(hasCreatePermission === undefined
        || hasEditPermission === undefined) {
        return <Loading/>
    }

    function handlePaymentConditionSelected(value) {
        if (value) {
            setFormData((prevData) => ({
                ...prevData,
                paymentCondition: value
            }));
        }
    }

    return (
        <div style={{width: '300px'}}>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Condição de Pagamento</div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Condição de Pagamento:</div>
                <select value={formData.paymentCondition}
                        onChange={(e) => handlePaymentConditionSelected(e.target.value)}>
                    <option value="">Selecione a Condição de Pagamento</option>
                    {renderPaymentConditionOptions()}
                </select>
            </div>


            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default PaymentTermManager;
