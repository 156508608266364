import React, {useState, useEffect, useRef} from 'react';

import DatePicker from "react-datepicker";
import {
    adjustDateToBrowserTimeZone,
    formatDateToAmericanFormat,
    getOnlyDate, UserPermission,
} from "../../../utils/utils";
import {
    getAllPaymentMethods, userHasPermission,
} from "../../../utils/requestUtils";
import Config from "../../../core/config";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import {makeRequest} from "../../../utils/httpRequest";
import Loading from "../../../core/Loading";
import {loadingAtom} from "../../../atoms";
import {useRecoilState} from "recoil";

const apiUrl = Config.apiUrl;
const ReportReceivablesByPaymentMethod = (props) => {

    const currentDate = new Date();
    const [sales, setSales] = useState([]);
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate);
    const [totalValue, setTotalValue] = useState(0);
    const navigate = useNavigate();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(-1);
    const [hasReportPermission, setReportPermission] = useState(undefined);

    useEffect(() => {
        if (hasReportPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasReportPermission]);


    const [loading, setLoading] = useRecoilState(loadingAtom);

    async function makeQuery() {
        try {

            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            const url = `${apiUrl}/api/receivables/report/receivables_by_payment_method?startDate=${formatDateToAmericanFormat(newStartDate)}&endDate=${formatDateToAmericanFormat(newEndDate)}&paymentMethodId=${selectedPaymentMethodId}`;
            setLoading(true);
            const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
            setSales(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        userHasPermission(UserPermission.REPORT_RECEIVABLES_BY_PAYMENT_METHOD).then(userHasPermission => setReportPermission(userHasPermission));
        getAllPaymentMethods().then(paymentMethods => setPaymentMethods(paymentMethods));
        makeQuery();
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    useEffect(() => {
        setTotalValue(sales.reduce((acc, obj) => acc + obj.totalValue, 0));
    }, [sales]);


    const handleQuery = async (e) => {
        await makeQuery();
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const [sortConfig, setSortConfig] = useState({key: 'totalValue', direction: 'descending'});

    const [sortedSales, setSortedSales] = useState([]);

    useEffect(() => {
        setSortedSales([...sales].sort((a, b) => {
            const aValue = sortConfig.key.includes('.') ? a[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : a[sortConfig.key];
            const bValue = sortConfig.key.includes('.') ? b[sortConfig.key.split('.')[0]][sortConfig.key.split('.')[1]] : b[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }));
    }, [sales, sortConfig]);
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return '';
    };

    if(hasReportPermission === undefined) {
        return <Loading/>
    }
    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Fechamento de Caixa</div>
            </div>
            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%'}}>

                <div style={{marginTop: '5px', alignItems: 'center', display: "flex"}}>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Data:
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <DatePicker
                                className="datepicker"
                                selected={startDate}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />

                            até
                            <DatePicker
                                className="datepicker"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                            />
                        </div>
                    </div>
                    <div style={{flexDirection: "column", alignItems: 'center'}}>

                        Forma de Pagamento
                        <div style={{display: 'flex', alignItems: 'center'}}>

                            <select value={selectedPaymentMethodId}
                                    onChange={(e) => setSelectedPaymentMethodId(e.target.value)}>
                                <option value="-1">Todos</option>
                                {paymentMethods.map((paymentMethod, index) => (
                                    <option key={index} value={paymentMethod.id}>
                                        {paymentMethod.name}
                                    </option>
                                ))}
                            </select>
                            <button onClick={handleQuery}>Consultar</button>
                        </div>

                    </div>


                </div>

            </div>

            <table style={{width: '100%', maxHeight: '100%'}}>

                <tr>
                    <th style={{cursor: "pointer", minWidth: "100px"}} onClick={() => handleSort('paymentMethod.name')}>
                        {getSortIcon('paymentMethod.name')} Forma de Pagamento
                    </th>
                    <th style={{cursor: "pointer", minWidth: "100px", textAlign: 'right'}} onClick={() => handleSort('totalValue')}>
                        {getSortIcon('totalValue')} Valor
                    </th>
                </tr>
                <tbody style={{overflowY: 'auto'}}>
                {sortedSales.map((sale, index) => (


                    <tr>
                        <td>
                            <div key={index}>{sale.paymentMethod.name}</div>
                        </td>
                        <td>
                            <div
                                key={index} style={{textAlign: 'right'}}>{sale.totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}</div>
                        </td>

                    </tr>
                ))}
                </tbody>
                {sales.length === 0 && (<tfoot>{loading ? 'Carregando...' : `Nenhum item encontrado.`}</tfoot>)}
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label">Valor Total: R$ {totalValue.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</div>
            </div>

        </>
    );
};

export default ReportReceivablesByPaymentMethod;
