import React, { useEffect, useState } from 'react';
import { makeRequest } from "../../utils/httpRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms";
import Config from "../../core/config";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import ConfirmButton from "../common/ConfirmButton";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {companyHasPermission, getCompanyById, isUserAdmin} from "../../utils/requestUtils";
import {CompanyPermission} from "../../utils/utils";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;

const CompanyManager = () => {
    const [, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');

    const newEntity = {
        id: undefined,
        name: '',
        email: '',
        permissions: [],
        password: '',
    };

    const [formData, setFormData] = useState(newEntity);
    const [availablePermissions, setAvailablePermissions] = useState([]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handlePermissionChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            permissions: checked
                ? [...prevData.permissions, value]
                : prevData.permissions.filter(permission => permission !== value)
        }));
    };

    async function fetchPermissions() {
        try {
            setLoading(true);
            const response = await makeRequest(apiUrl + '/api/companypermission/companies_permission_info', 'GET', {
                'Useruuid': Cookies.get('userUUID'),
            });

            setAvailablePermissions(response);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        } finally {
            setLoading(false);
        }
    }


    async function makeQuery() {
        if (id) {
            try {
                setLoading(true);
                const response = await getCompanyById(id);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        isUserAdmin().then(userAdmin => {
            if (!userAdmin)
                navigate('/unauthorizedaccess');
        });
    }, []);

    useEffect(() => {
        fetchPermissions();
        makeQuery();
    }, [id]);

    const onConfirm = async () => {
        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                navigate('/admin/companylist');
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {
        if (!formData.name || formData.name === '') {
            alert('Preencha o campo nome.');
            return false;
        }
        return true;
    }

    async function saveEntity() {
        const userUUID = Cookies.get('userUUID');
        const url = apiUrl + '/api/companies';
        setLoading(true);
        try {
            await makeRequest(url, id ? 'PUT' : 'POST', {
                'Content-Type': 'application/json',
                'Useruuid': userUUID,
            }, JSON.stringify(formData));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{width: '500px'}}>
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Empresa</div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Nome:</div>
                <input type="text" style={{width: '300px'}} value={formData.name} name="name"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Cidade:</div>
                <input type="text" style={{width: '300px'}} value={formData.city} name="city"
                       onChange={handleChange}/>
            </div>


            <div style={{marginTop: '5px', overflowY: 'auto', maxHeight: '300px'}}>
                <div style={{width: '180px'}}>Permissões:</div>
                {availablePermissions.map((permission) => (
                    <div key={permission.permission} style={{display: "flex", alignItems: "center"}}>
                        <label style={{display: "flex", alignItems: "center"}}>
                            <input
                                type="checkbox"
                                value={permission.permission}
                                checked={formData.permissions.includes(permission.permission)}
                                onChange={handlePermissionChange}
                            />
                            {permission.description}
                        </label>
                    </div>
                ))}
            </div>

            <ActionButtonsManagerView>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>
        </div>
    );
};

export default CompanyManager;
