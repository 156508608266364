import './css/App.css';
import React, {useEffect, useRef, useState} from "react";
import {loadingAtom} from "./atoms";
import {useRecoilState} from 'recoil';
import Loading from "./core/Loading";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {getCompanyByUserUUID, isUserAdmin, userHasPermission, userUUIDExists} from "./utils/requestUtils";
import homeIcon from './img/icons8-home-24.png';
import {UserPermission} from "./utils/utils";


function Menu({children}) {

    const [currentLoading, setCurrentLoading] = useRecoilState(loadingAtom);

    const [activeMenu, setActiveMenu] = useState(null);
    const navigate = useNavigate();

    const [hasListUserPermission, setListUserPermission] = useState(false);
    const [hasListSectorPermission, setListSectorPermission] = useState(false);
    const [hasListPaymentTermPermission, setListPaymentTermPermission] = useState(false);
    const [hasListServicePermission, setListServicePermission] = useState(false);
    const [hasListReceivablePermission, setListReceivablePermission] = useState(false);
    const [hasListPaymentMethodPermission, setListPaymentMethodPermission] = useState(false);

    const [hasReportSalesBySectorPermission, setReportSalesBySectorPermission] = useState(false);
    const [hasReportSalesByUserPermission, setReportSalesByUserPermission] = useState(false);
    const [hasReportReceivablesByPaymentMethodPermission, setReportReceivablesByPaymentMethodPermission] = useState(false);
    const [hasReportReceivablesDetailedPermission, setReportReceivablesDetailedPermission] = useState(false);
    const [userAdmin, setUserAdmin] = useState(false);


    useEffect(() => {
        userHasPermission(UserPermission.LIST_USER).then(userHasPermission => setListUserPermission(userHasPermission));
        userHasPermission(UserPermission.LIST_SECTOR).then(userHasPermission => setListSectorPermission(userHasPermission));
        userHasPermission(UserPermission.LIST_PAYMENT_TERM).then(userHasPermission => setListPaymentTermPermission(userHasPermission));
        userHasPermission(UserPermission.LIST_SERVICE).then(userHasPermission => setListServicePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_RECEIVABLE).then(userHasPermission => setListReceivablePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_PAYMENT_METHOD).then(userHasPermission => setListPaymentMethodPermission(userHasPermission));
        userHasPermission(UserPermission.REPORT_SALES_BY_SECTOR).then(userHasPermission => setReportSalesBySectorPermission(userHasPermission));
        userHasPermission(UserPermission.REPORT_SALES_BY_USER).then(userHasPermission => setReportSalesByUserPermission(userHasPermission));
        userHasPermission(UserPermission.REPORT_RECEIVABLES_BY_PAYMENT_METHOD).then(userHasPermission => setReportReceivablesByPaymentMethodPermission(userHasPermission));
        userHasPermission(UserPermission.REPORT_RECEIVABLES_DETAILED).then(userHasPermission => setReportReceivablesDetailedPermission(userHasPermission));
        isUserAdmin().then(userAdmin => setUserAdmin(userAdmin));
    }, []);

    const checkIfUserIsLogged = () => {
        userUUIDExists().catch(r => {
            if (r.statusCode === 404) {
                navigate('/login');
            }
        });
    }

    const handleMenuClick = (menu) => {
        checkIfUserIsLogged();
        if (activeMenu === menu && isMenuVisible) {
            setMenuVisible(false);
            setActiveMenu(null);
        } else {
            setActiveMenu(menu);
            if (menu === 'Financeiro' || menu === 'Serviço' || menu === 'Relatórios' || menu === 'Usuários' || menu === 'Empresas') {
                setMenuVisible(true);
            } else {
                setMenuVisible(false);
            }
        }
    };


    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuRef = useRef(null);


    const [companyName, setCompanyName] = useState(undefined);
    const [userName, setUserName] = useState(undefined);
    useEffect(() => {

        async function fetchData() {
            try {
                const userCookies = Cookies.get('user');

                const user = JSON.parse(userCookies);
                setUserName(user.name);

                const result = await getCompanyByUserUUID();
                setCompanyName(result.name);
            } catch (error) {
                console.error('Erro ao buscar os dados:', error);
            }
        }

        fetchData();

        const handleOutsideClick = (event) => {
            // Se o clique não foi dentro do menu, oculta o menu
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };

        // Adiciona um ouvinte de evento global para cliques fora do menu
        document.addEventListener('click', handleOutsideClick);

        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []); // Executa apenas uma vez durante a montagem do componente

    const toggleMenu = () => {
        setMenuVisible(!isMenuVisible);
    };

    function handleExitClickButton() {
        Cookies.remove('userUUID');
        Cookies.remove('user');
        localStorage.removeItem('products');
        localStorage.removeItem('customers');
        localStorage.removeItem('tables');
        localStorage.removeItem('sale');
        navigate('/login');
    }

    const userCookies = Cookies.get('user');
    //let isUserAdmin = true;
    // if (userCookies) {
    //     const user = JSON.parse(userCookies);
    //     isUserAdmin = user.email === 'renanedpedroso@gmail.com';
    // }

    if (companyName === undefined
        || userName === undefined
        || hasListUserPermission === undefined
        || hasListPaymentTermPermission === undefined
        || hasReportSalesBySectorPermission === undefined
        || hasReportSalesByUserPermission === undefined
        || hasReportReceivablesByPaymentMethodPermission === undefined
        || hasReportReceivablesDetailedPermission === undefined
        || hasListServicePermission === undefined
        || hasListSectorPermission === undefined
        || hasListReceivablePermission === undefined
        || hasListPaymentMethodPermission === undefined
    ) {
        return <Loading/>;
    }

    const url = window.location.pathname;
    const admin = url.startsWith('/admin') && userAdmin;

    return (
        <div>
            {companyName} - {userName}
            {currentLoading && (<Loading/>)}
            <div ref={menuRef} style={{
                zIndex: 1000,
                width: '100%',
                backgroundColor: "#333",
                display: "flex",
                flexWrap: "wrap",
                minHeight: "50px",
                alignItems: "center"
            }}>
                <div>
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            setMenuVisible(false);
                            navigate(admin ? '/admin' : '/app');
                        }}
                        className={'menu-button ' + (activeMenu === 'Inicio' ? 'active' : '')}
                    >
                        <img src={homeIcon} alt="Home" style={{width: '20px', height: '20px'}}/>
                    </div>
                </div>
                {!admin ?
                    <>

                        <div>

                            {(hasListServicePermission || hasListSectorPermission) &&
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu();
                                        handleMenuClick('Serviço');
                                    }}
                                    className={'menu-button ' + (activeMenu === 'Serviço' ? 'active' : '')}
                                >
                                    Serviço
                                </div>
                            }

                            {isMenuVisible && activeMenu === 'Serviço' && (
                                <ul className="submenu">

                                    {hasListServicePermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/salelist');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Serviço' ? 'active' : '')}
                                            >
                                                Serviços
                                            </div>
                                        </li>
                                    }
                                    {hasListSectorPermission &&
                                        <li>

                                            <div
                                                onClick={() => {
                                                    navigate('/app/sectorlist');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Serviço' ? 'active' : '')}
                                            >
                                                Setores
                                            </div>

                                        </li>
                                    }

                                </ul>
                            )
                            }
                        </div>
                        <div>

                            {(hasListReceivablePermission || hasListPaymentMethodPermission) &&
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu();
                                        handleMenuClick('Financeiro');
                                    }}
                                    className={'menu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                >
                                    Financeiro
                                </div>
                            }

                            {isMenuVisible && activeMenu === 'Financeiro' && (
                                <ul className="submenu">

                                    {hasListReceivablePermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/receivablelist');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                            >
                                                Contas a Receber
                                            </div>
                                        </li>
                                    }

                                    {hasListPaymentTermPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/paymenttermlist');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                            >
                                                Condições de Pagamento
                                            </div>
                                        </li>
                                    }

                                    {hasListPaymentMethodPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/paymentmethodlist');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Financeiro' ? 'active' : '')}
                                            >
                                                Formas de Pagamento
                                            </div>
                                        </li>
                                    }


                                </ul>
                            )
                            }
                        </div>
                        <div>

                            {(hasReportSalesBySectorPermission ||
                                    hasReportSalesByUserPermission ||
                                    hasReportReceivablesByPaymentMethodPermission ||
                                    hasReportReceivablesDetailedPermission) &&
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleMenu();
                                        handleMenuClick('Relatórios');
                                    }}
                                    className={'menu-button ' + (activeMenu === 'Relatórios' ? 'active' : '')}
                                >
                                    Relatórios
                                </div>
                            }

                            {isMenuVisible && activeMenu === 'Relatórios' && (
                                <ul className="submenu">

                                    {hasReportSalesBySectorPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/reports/salesbysector');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Relatórios' ? 'active' : '')}
                                            >
                                                Vendas por setor
                                            </div>
                                        </li>
                                    }

                                    {hasReportSalesByUserPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/reports/salesbyuser');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Relatórios' ? 'active' : '')}
                                            >
                                                Vendas por vendedor
                                            </div>
                                        </li>
                                    }

                                    {hasReportReceivablesByPaymentMethodPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/reports/receivablesbypaymentmethod');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Relatórios' ? 'active' : '')}
                                            >
                                                Fechamento de Caixa Resumido
                                            </div>
                                        </li>
                                    }

                                    {hasReportReceivablesDetailedPermission &&
                                        <li>
                                            <div
                                                onClick={() => {
                                                    navigate('/app/reports/receivables');
                                                    setMenuVisible(false);
                                                }}
                                                className={'submenu-button ' + (activeMenu === 'Relatórios' ? 'active' : '')}
                                            >
                                                Fechamento de Caixa Detalhado
                                            </div>
                                        </li>
                                    }

                                </ul>
                            )
                            }
                        </div>

                    </>
                    :
                    <>
                        <div>

                            <div
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleMenu();
                                    handleMenuClick('Empresas');
                                }}
                                className={'menu-button ' + (activeMenu === 'Empresas' ? 'active' : '')}
                            >
                                Empresas
                            </div>


                            {isMenuVisible && activeMenu === 'Empresas' && (
                                <ul className="submenu">

                                    <li>
                                        <div
                                            onClick={() => {
                                                navigate('/admin/companylist');
                                                setMenuVisible(false);
                                            }}
                                            className={'submenu-button ' + (activeMenu === 'Empresas' ? 'active' : '')}
                                        >
                                            Empresas
                                        </div>
                                    </li>

                                </ul>
                            )
                            }
                        </div>

                    </>}
                {(admin || hasListUserPermission ) && <div>

                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMenu();
                            handleMenuClick('Usuários');
                        }}
                        className={'menu-button ' + (activeMenu === 'Usuários' ? 'active' : '')}
                    >
                        Usuários
                    </div>


                    {isMenuVisible && activeMenu === 'Usuários' && (
                        <ul className="submenu">

                            <li>
                                <div
                                    onClick={() => {
                                        navigate(`/${admin ? 'admin' : 'app'}/userlist`);
                                        setMenuVisible(false);
                                    }}
                                    className={'submenu-button ' + (activeMenu === 'Usuários' ? 'active' : '')}
                                >
                                    Usuários
                                </div>
                            </li>

                        </ul>
                    )
                    }
                </div>
                }
                <div>
                    <div
                        onClick={() => {
                            toggleMenu();
                            handleExitClickButton();
                        }}
                        className="menu-button"
                    >
                        Sair
                    </div>
                </div>
            </div>
            {
                children
            }
        </div>
    )
        ;
}

export default Menu;
