import {makeRequest} from "./httpRequest";
import Config from "../core/config";
import {formatDateToAmericanFormat, getOnlyDate} from "./utils";
import Cookies from "js-cookie";
import {saveAs} from 'file-saver';

const {v4: uuidv4} = require('uuid');

const apiUrl = Config.apiUrl;

export async function getAllPaymentMethods() {

    try {
        const url = apiUrl + '/api/paymentMethods';
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function customerKeyExists(customerKey) {
    const url = apiUrl + '/api/customerKey/customerKey=' + customerKey;
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function userUUIDExists() {
    const url = apiUrl + '/api/users/userUUID';
    return makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
}

export async function getSaleById(id) {

    try {
        const url = apiUrl + '/api/sales/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function getCompanyByUserUUID() {

    try {
        const url = apiUrl + '/api/companies/userUUID';
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function downloadContract(id, code) {
    try {
        const url = `${apiUrl}/api/contract/${id}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'contrato_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function downloadDeliveryStatement(id, code) {
    try {
        const url = `${apiUrl}/api/deliveryStatement?id=${id}`;
        const response = await fetch(url, {headers: {'Useruuid': Cookies.get('userUUID')}});
        const data = await response.blob();

        saveAs(data, 'termo_de_entrega_' + code + '.pdf');

    } catch (error) {
        console.error('Erro ao obter PDF:', error);
    }
}

export async function findSectorsByQueryString(query) {
    try {
        // const sectorInLocalStorageJson = localStorage.getItem('sectors');
        // if(sectorInLocalStorageJson !== undefined) {
        //     const sectorsInLocalStorage = JSON.parse(sectorInLocalStorageJson);
        //     if (sectorsInLocalStorage !== undefined && sectorsInLocalStorage !== null && sectorsInLocalStorage.length > 0) {
        //         return sectorsInLocalStorage;
        //     }
        // }
        const url = apiUrl + '/api/sectors/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('sectors', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findPaymentTermsByQueryString(query) {
    try {
        // const sectorInLocalStorageJson = localStorage.getItem('sectors');
        // if(sectorInLocalStorageJson !== undefined) {
        //     const sectorsInLocalStorage = JSON.parse(sectorInLocalStorageJson);
        //     if (sectorsInLocalStorage !== undefined && sectorsInLocalStorage !== null && sectorsInLocalStorage.length > 0) {
        //         return sectorsInLocalStorage;
        //     }
        // }
        const url = apiUrl + '/api/payment_terms/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('sectors', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findUsersByQueryString(query) {
    try {
        const url = apiUrl + '/api/users/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('users', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findUsersByCompanyIdAndQueryString(query) {
    try {
        const url = apiUrl + '/api/users/company/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('users', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function findByDateAndStatus(startDate, endDate, status = 'OPENING',) {
    try {
        const url = new URL(apiUrl + '/api/sales/queryByDateAndStatus');

        const params = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            status: status
        };

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

        // const url = apiUrl + '/api/sales/query=' + (query && query !== '' ? (query) : '');
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        localStorage.setItem('sales', JSON.stringify(response));
        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function getReceivableById(id) {

    try {
        const url = apiUrl + '/api/receivables/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export const downloadSaleFile = async (saleId, filename, userUUID) => {
    try {
        const response = await fetch(`${apiUrl}/api/sales/${saleId}/file/${filename}`, {
            method: 'GET',
            headers: {
                'userUUID': userUUID,
            },
        });

        if (!response.ok) {
            throw new Error('Erro ao baixar o arquivo. Status: ' + response.status);
        }

        const blob = await response.blob();

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const contentDisposition = response.headers.get('Content-Disposition');
        const fileName = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, '') : filename;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
        alert('Erro ao baixar o arquivo. Tente novamente.');
    }
};


export async function updateSaleStatus(id, status) {
    try {
        const url = `${apiUrl}/api/sales/${id}/status`;
        return JSON.stringify(await makeRequest(url, 'PATCH', {
            'Content-Type': 'application/json',
            'Useruuid': Cookies.get('userUUID')
        }, JSON.stringify(status)));
    } catch (error) {
        console.error('Error fetching data:', error);
    }


}

export async function userHasPermission(permission) {
    try {
        const url = apiUrl + '/api/userpermission/check?permission=' + permission;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response.hasPermission;
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
}

export async function findCompaniesByQueryString(query) {
    try {
        const url = apiUrl + '/api/companies/query=' + (query && query !== '' ? (query) : '');
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

export async function companyHasPermission(permission) {
    try {
        const url = apiUrl + '/api/companypermission/check?permission=' + permission;
        const response = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
        return response.hasPermission;
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
}

export async function getCompanyById(id) {

    try {
        const url = apiUrl + '/api/companies/id=' + id;
        return await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}

export async function isUserAdmin(){
    const url = `${apiUrl}/api/users/check_admin_user`;
    const response1 = await makeRequest(url, 'GET', {'Useruuid': Cookies.get('userUUID')});

    return response1.isAdmin;

}
