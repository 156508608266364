import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {findSectorsByQueryString, userHasPermission} from "../../utils/requestUtils";
import {UserPermission} from "../../utils/utils";
import Loading from "../../core/Loading";

const apiUrl = Config.apiUrl;
const SectorList = () => {
    let [sectors, setSectors] = useState([]);
    const [queryString, setQueryString] = useState('');
    const inputQueryString = useRef(null);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const navigate = useNavigate();
    const [hasListPermission, setListPermission] = useState(undefined);
    const [hasEditPermission, setEditPermission] = useState(undefined);
    const [hasCreatePermission, setCreatePermission] = useState(undefined);
    const [hasRemovePermission, setRemovePermission] = useState(undefined);

    async function makeQuery(query) {
        try {
            setLoading(true);
            const response = await findSectorsByQueryString(query);
            setSectors(response);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (hasListPermission === false) {
            navigate('/unauthorizedaccess');
        }
    }, [hasListPermission]);


    useEffect(() => {
        if (inputQueryString.current) {
            inputQueryString.current.focus();
        }
        userHasPermission(UserPermission.EDIT_SECTOR).then(userHasPermission => setEditPermission(userHasPermission));
        userHasPermission(UserPermission.CREATE_SECTOR).then(userHasPermission => setCreatePermission(userHasPermission));
        userHasPermission(UserPermission.LIST_SECTOR).then(userHasPermission => setListPermission(userHasPermission));
        userHasPermission(UserPermission.REMOVE_SECTOR).then(userHasPermission => setRemovePermission(userHasPermission));
        makeQuery('');
    }, []); // Empty dependency array ensures that useEffect runs only once, similar to componentDidMount

    async function deleteSector(id) {
        try {
            setLoading(true);
            const url = apiUrl + '/api/sectors/' + id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            await makeQuery(queryString);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleInputChange = (e) => {
        const string = e.target.value;
        if (string.length >= 3 || string.length === 0) {
            makeQuery(string);
        }
        setQueryString(string);
    };

    if (hasListPermission === undefined
        || hasEditPermission === undefined
        || hasCreatePermission === undefined
        || hasRemovePermission === undefined
    ) {
        return <Loading></Loading>;
    }

    return (
        <>
            <div style={{display: "flex", alignItems: "center", gap: '10px'}}>
                <div className="header-label">Consulta de Setores</div>
                {hasCreatePermission &&
                    <button className={"new-button"} onClick={() => navigate('/app/sector')}>Novo</button>
                }
            </div>

            <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
            </div>

            <table style={{width: '580px'}}>

                <tr>
                    <th>
                        Código
                    </th>
                    <th>
                        Nome
                    </th>
                    <th/>
                    <th/>
                </tr>
                <tbody style={{overflowY: 'auto'}}>

                {sectors.map((sector, index) => (


                    <tr style={{backgroundColor: sector.color}}>
                        <td>
                            <div key={index}>{sector.code}</div>
                        </td>
                        <td>
                            <div key={index}>{sector.name}</div>
                        </td>
                        <td>
                            {hasEditPermission &&
                                <button onClick={async () => {
                                    await navigate('/app/sector?id=' + sector.id);
                                }}>Editar
                                </button>
                            }
                        </td>
                        <td>
                            {hasRemovePermission &&
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await deleteSector(sector.id);
                                    }
                                }}>Excluir
                                </button>
                            }
                        </td>

                    </tr>
                ))}
                </tbody>
                {sectors.length === 0 && (<tfoot>{loading ? 'Carregando...' : `Nenhum item encontrado.`}</tfoot>)}
            </table>


        </>
    );
};

export default SectorList;
